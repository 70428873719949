"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducerStatusToJSON = exports.ProducerStatusFromJSONTyped = exports.ProducerStatusFromJSON = exports.ProducerStatus = void 0;
/**
 *
 * @export
 */
exports.ProducerStatus = {
    OnBoarding: 'ON_BOARDING',
    Active: 'ACTIVE'
};
function ProducerStatusFromJSON(json) {
    return ProducerStatusFromJSONTyped(json, false);
}
exports.ProducerStatusFromJSON = ProducerStatusFromJSON;
function ProducerStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ProducerStatusFromJSONTyped = ProducerStatusFromJSONTyped;
function ProducerStatusToJSON(value) {
    return value;
}
exports.ProducerStatusToJSON = ProducerStatusToJSON;
