"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducerToJSON = exports.ProducerFromJSONTyped = exports.ProducerFromJSON = exports.instanceOfProducer = void 0;
var runtime_1 = require("../runtime");
var ProducerStatus_1 = require("./ProducerStatus");
var Production_1 = require("./Production");
var Sale_1 = require("./Sale");
var StripeAccount_1 = require("./StripeAccount");
var User_1 = require("./User");
/**
 * Check if a given object implements the Producer interface.
 */
function instanceOfProducer(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProducer = instanceOfProducer;
function ProducerFromJSON(json) {
    return ProducerFromJSONTyped(json, false);
}
exports.ProducerFromJSON = ProducerFromJSON;
function ProducerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, User_1.UserFromJSON)(json['user']),
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, ProducerStatus_1.ProducerStatusFromJSON)(json['status']),
        'productions': !(0, runtime_1.exists)(json, 'productions') ? undefined : (json['productions'].map(Production_1.ProductionFromJSON)),
        'sales': !(0, runtime_1.exists)(json, 'sales') ? undefined : (json['sales'].map(Sale_1.SaleFromJSON)),
        'stripeAccount': !(0, runtime_1.exists)(json, 'stripeAccount') ? undefined : (0, StripeAccount_1.StripeAccountFromJSON)(json['stripeAccount']),
        'slideShowUrl': !(0, runtime_1.exists)(json, 'slideShowUrl') ? undefined : json['slideShowUrl'],
        'websiteUrl': !(0, runtime_1.exists)(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'farmName': !(0, runtime_1.exists)(json, 'farmName') ? undefined : json['farmName'],
    };
}
exports.ProducerFromJSONTyped = ProducerFromJSONTyped;
function ProducerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, User_1.UserToJSON)(value.user),
        'id': value.id,
        'status': (0, ProducerStatus_1.ProducerStatusToJSON)(value.status),
        'productions': value.productions === undefined ? undefined : (value.productions.map(Production_1.ProductionToJSON)),
        'sales': value.sales === undefined ? undefined : (value.sales.map(Sale_1.SaleToJSON)),
        'stripeAccount': (0, StripeAccount_1.StripeAccountToJSON)(value.stripeAccount),
        'slideShowUrl': value.slideShowUrl,
        'websiteUrl': value.websiteUrl,
        'farmName': value.farmName,
    };
}
exports.ProducerToJSON = ProducerToJSON;
