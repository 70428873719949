"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaleToJSON = exports.SaleFromJSONTyped = exports.SaleFromJSON = exports.instanceOfSale = void 0;
var runtime_1 = require("../runtime");
var Order_1 = require("./Order");
var Producer_1 = require("./Producer");
var Production_1 = require("./Production");
/**
 * Check if a given object implements the Sale interface.
 */
function instanceOfSale(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSale = instanceOfSale;
function SaleFromJSON(json) {
    return SaleFromJSONTyped(json, false);
}
exports.SaleFromJSON = SaleFromJSON;
function SaleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'seller': !(0, runtime_1.exists)(json, 'seller') ? undefined : (0, Producer_1.ProducerFromJSON)(json['seller']),
        'productions': !(0, runtime_1.exists)(json, 'productions') ? undefined : (json['productions'].map(Production_1.ProductionFromJSON)),
        'orders': !(0, runtime_1.exists)(json, 'orders') ? undefined : (json['orders'].map(Order_1.OrderFromJSON)),
        'deliveryStart': !(0, runtime_1.exists)(json, 'deliveryStart') ? undefined : (new Date(json['deliveryStart'])),
        'deliveryStop': !(0, runtime_1.exists)(json, 'deliveryStop') ? undefined : (new Date(json['deliveryStop'])),
        'deliveryAddressName': !(0, runtime_1.exists)(json, 'deliveryAddressName') ? undefined : json['deliveryAddressName'],
        'deliveryAddressLine1': !(0, runtime_1.exists)(json, 'deliveryAddressLine1') ? undefined : json['deliveryAddressLine1'],
        'deliveryAddressLine2': !(0, runtime_1.exists)(json, 'deliveryAddressLine2') ? undefined : json['deliveryAddressLine2'],
        'deliveryCity': !(0, runtime_1.exists)(json, 'deliveryCity') ? undefined : json['deliveryCity'],
        'deliveryZipCode': !(0, runtime_1.exists)(json, 'deliveryZipCode') ? undefined : json['deliveryZipCode'],
    };
}
exports.SaleFromJSONTyped = SaleFromJSONTyped;
function SaleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'seller': (0, Producer_1.ProducerToJSON)(value.seller),
        'productions': value.productions === undefined ? undefined : (value.productions.map(Production_1.ProductionToJSON)),
        'orders': value.orders === undefined ? undefined : (value.orders.map(Order_1.OrderToJSON)),
        'deliveryStart': value.deliveryStart === undefined ? undefined : (value.deliveryStart.toISOString()),
        'deliveryStop': value.deliveryStop === undefined ? undefined : (value.deliveryStop.toISOString()),
        'deliveryAddressName': value.deliveryAddressName,
        'deliveryAddressLine1': value.deliveryAddressLine1,
        'deliveryAddressLine2': value.deliveryAddressLine2,
        'deliveryCity': value.deliveryCity,
        'deliveryZipCode': value.deliveryZipCode,
    };
}
exports.SaleToJSON = SaleToJSON;
