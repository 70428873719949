"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageLotToJSON = exports.PackageLotFromJSONTyped = exports.PackageLotFromJSON = exports.instanceOfPackageLot = void 0;
var runtime_1 = require("../runtime");
var Production_1 = require("./Production");
/**
 * Check if a given object implements the PackageLot interface.
 */
function instanceOfPackageLot(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPackageLot = instanceOfPackageLot;
function PackageLotFromJSON(json) {
    return PackageLotFromJSONTyped(json, false);
}
exports.PackageLotFromJSON = PackageLotFromJSON;
function PackageLotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'production': !(0, runtime_1.exists)(json, 'production') ? undefined : (0, Production_1.ProductionFromJSON)(json['production']),
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'photo': !(0, runtime_1.exists)(json, 'photo') ? undefined : json['photo'],
        'netWeight': !(0, runtime_1.exists)(json, 'netWeight') ? undefined : json['netWeight'],
        'unitPrice': !(0, runtime_1.exists)(json, 'unitPrice') ? undefined : json['unitPrice'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'quantitySold': !(0, runtime_1.exists)(json, 'quantitySold') ? undefined : json['quantitySold'],
    };
}
exports.PackageLotFromJSONTyped = PackageLotFromJSONTyped;
function PackageLotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'production': (0, Production_1.ProductionToJSON)(value.production),
        'label': value.label,
        'description': value.description,
        'photo': value.photo,
        'netWeight': value.netWeight,
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
        'quantitySold': value.quantitySold,
    };
}
exports.PackageLotToJSON = PackageLotToJSON;
