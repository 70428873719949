"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderToJSON = exports.OrderFromJSONTyped = exports.OrderFromJSON = exports.instanceOfOrder = void 0;
var runtime_1 = require("../runtime");
var Customer_1 = require("./Customer");
var Invoice_1 = require("./Invoice");
var OrderItem_1 = require("./OrderItem");
var OrderStatus_1 = require("./OrderStatus");
var Sale_1 = require("./Sale");
var StripePayment_1 = require("./StripePayment");
/**
 * Check if a given object implements the Order interface.
 */
function instanceOfOrder(value) {
    var isInstance = true;
    isInstance = isInstance && "customer" in value;
    return isInstance;
}
exports.instanceOfOrder = instanceOfOrder;
function OrderFromJSON(json) {
    return OrderFromJSONTyped(json, false);
}
exports.OrderFromJSON = OrderFromJSON;
function OrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'invoice': !(0, runtime_1.exists)(json, 'invoice') ? undefined : (0, Invoice_1.InvoiceFromJSON)(json['invoice']),
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(OrderItem_1.OrderItemFromJSON)),
        'customer': (0, Customer_1.CustomerFromJSON)(json['customer']),
        'sale': !(0, runtime_1.exists)(json, 'sale') ? undefined : (0, Sale_1.SaleFromJSON)(json['sale']),
        'payment': !(0, runtime_1.exists)(json, 'payment') ? undefined : (0, StripePayment_1.StripePaymentFromJSON)(json['payment']),
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, OrderStatus_1.OrderStatusFromJSON)(json['status']),
    };
}
exports.OrderFromJSONTyped = OrderFromJSONTyped;
function OrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'invoice': (0, Invoice_1.InvoiceToJSON)(value.invoice),
        'items': value.items === undefined ? undefined : (value.items.map(OrderItem_1.OrderItemToJSON)),
        'customer': (0, Customer_1.CustomerToJSON)(value.customer),
        'sale': (0, Sale_1.SaleToJSON)(value.sale),
        'payment': (0, StripePayment_1.StripePaymentToJSON)(value.payment),
        'status': (0, OrderStatus_1.OrderStatusToJSON)(value.status),
    };
}
exports.OrderToJSON = OrderToJSON;
