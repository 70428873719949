"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerToJSON = exports.CustomerFromJSONTyped = exports.CustomerFromJSON = exports.instanceOfCustomer = void 0;
var runtime_1 = require("../runtime");
var User_1 = require("./User");
/**
 * Check if a given object implements the Customer interface.
 */
function instanceOfCustomer(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCustomer = instanceOfCustomer;
function CustomerFromJSON(json) {
    return CustomerFromJSONTyped(json, false);
}
exports.CustomerFromJSON = CustomerFromJSON;
function CustomerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, User_1.UserFromJSON)(json['user']),
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.CustomerFromJSONTyped = CustomerFromJSONTyped;
function CustomerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, User_1.UserToJSON)(value.user),
        'id': value.id,
    };
}
exports.CustomerToJSON = CustomerToJSON;
