"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripePaymentToJSON = exports.StripePaymentFromJSONTyped = exports.StripePaymentFromJSON = exports.instanceOfStripePayment = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the StripePayment interface.
 */
function instanceOfStripePayment(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfStripePayment = instanceOfStripePayment;
function StripePaymentFromJSON(json) {
    return StripePaymentFromJSONTyped(json, false);
}
exports.StripePaymentFromJSON = StripePaymentFromJSON;
function StripePaymentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'checkoutSessionId': !(0, runtime_1.exists)(json, 'checkoutSessionId') ? undefined : json['checkoutSessionId'],
        'paymentUrl': !(0, runtime_1.exists)(json, 'paymentUrl') ? undefined : json['paymentUrl'],
    };
}
exports.StripePaymentFromJSONTyped = StripePaymentFromJSONTyped;
function StripePaymentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'checkoutSessionId': value.checkoutSessionId,
        'paymentUrl': value.paymentUrl,
    };
}
exports.StripePaymentToJSON = StripePaymentToJSON;
