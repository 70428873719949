"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductionToJSON = exports.ProductionFromJSONTyped = exports.ProductionFromJSON = exports.instanceOfProduction = exports.ProductionProductionTypeEnum = void 0;
var runtime_1 = require("../runtime");
var PackageLot_1 = require("./PackageLot");
var Producer_1 = require("./Producer");
var Sale_1 = require("./Sale");
var _1 = require("./");
/**
 * @export
 */
exports.ProductionProductionTypeEnum = {
    BeefProduction: 'BeefProduction',
    HonneyProduction: 'HonneyProduction'
};
/**
 * Check if a given object implements the Production interface.
 */
function instanceOfProduction(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProduction = instanceOfProduction;
function ProductionFromJSON(json) {
    return ProductionFromJSONTyped(json, false);
}
exports.ProductionFromJSON = ProductionFromJSON;
function ProductionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['productionType'] === 'BeefProduction') {
            return (0, _1.BeefProductionFromJSONTyped)(json, true);
        }
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'productionType': !(0, runtime_1.exists)(json, 'productionType') ? undefined : json['productionType'],
        'sales': !(0, runtime_1.exists)(json, 'sales') ? undefined : (json['sales'].map(Sale_1.SaleFromJSON)),
        'producer': !(0, runtime_1.exists)(json, 'producer') ? undefined : (0, Producer_1.ProducerFromJSON)(json['producer']),
        'lots': !(0, runtime_1.exists)(json, 'lots') ? undefined : (json['lots'].map(PackageLot_1.PackageLotFromJSON)),
    };
}
exports.ProductionFromJSONTyped = ProductionFromJSONTyped;
function ProductionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'productionType': value.productionType,
        'sales': value.sales === undefined ? undefined : (value.sales.map(Sale_1.SaleToJSON)),
        'producer': (0, Producer_1.ProducerToJSON)(value.producer),
        'lots': value.lots === undefined ? undefined : (value.lots.map(PackageLot_1.PackageLotToJSON)),
    };
}
exports.ProductionToJSON = ProductionToJSON;
