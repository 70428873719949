"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItemToJSON = exports.OrderItemFromJSONTyped = exports.OrderItemFromJSON = exports.instanceOfOrderItem = void 0;
var runtime_1 = require("../runtime");
var Order_1 = require("./Order");
var PackageLot_1 = require("./PackageLot");
/**
 * Check if a given object implements the OrderItem interface.
 */
function instanceOfOrderItem(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfOrderItem = instanceOfOrderItem;
function OrderItemFromJSON(json) {
    return OrderItemFromJSONTyped(json, false);
}
exports.OrderItemFromJSON = OrderItemFromJSON;
function OrderItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'order': !(0, runtime_1.exists)(json, 'order') ? undefined : (0, Order_1.OrderFromJSON)(json['order']),
        'packageLot': !(0, runtime_1.exists)(json, 'packageLot') ? undefined : (0, PackageLot_1.PackageLotFromJSON)(json['packageLot']),
        'unitPrice': !(0, runtime_1.exists)(json, 'unitPrice') ? undefined : json['unitPrice'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
    };
}
exports.OrderItemFromJSONTyped = OrderItemFromJSONTyped;
function OrderItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'order': (0, Order_1.OrderToJSON)(value.order),
        'packageLot': (0, PackageLot_1.PackageLotToJSON)(value.packageLot),
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
    };
}
exports.OrderItemToJSON = OrderItemToJSON;
