import {BeefProduction} from "@viandeendirect/api/dist/models/BeefProduction"
import {PackageTemplate} from "@viandeendirect/api/dist/models/PackageTemplate"
import {Production} from "@viandeendirect/api/dist/models/Production"

export class MockApiProductions {

    getProductions(): Array<Production> {
        const production1: BeefProduction = {
            id: 1,
            productionType: 'BeefProduction',
            producer: undefined,
            lots: undefined,
            sales: undefined
        }

        const production2: BeefProduction = {
            id: 2,
            productionType: 'BeefProduction',
            producer: undefined,
            lots: undefined,
            sales: undefined
        }
        return [production1, production2]
    }
    
    getBeefProduction(): BeefProduction {
        return {
            id: 1,
            animalIdentifier: '1234',
            birthDate: new Date('2022-10-01T10:00:00'),
            slaughterDate: new Date('2023-10-01T10:00:00'),
            cuttingDate: new Date('2024-10-01T10:00:00'),
            warmCarcassWeight: 450,
            animalType: 'BEEF_COW',
            birthFarm: 'La ferme du puis',
            producer: {
                farmName: 'La ferme des Marronniers',
                slideShowUrl: 'https://docs.google.com/presentation/d/1sqDR1v9AZLQYVJo2iT1gAVZ4UX25RamEBXeTmiUWRa4/embed?start=true&loop=true&delayms=3000',
                websiteUrl: 'https://www.lemonde.fr'
                },
            lots: [
                {
                    id: 1,
                    label: 'Le petit coli de steaks',
                    description: 'Un coli avec uniquement des steaks et des steaks hachés',
                    netWeight: 5.0,
                    unitPrice: 17.0,
                    quantity: 10,
                    quantitySold: 2,
                    photo: 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII='
                },
                {
                    id: 2,
                    label: 'Le coli tradition',
                    description: 'Un coli avec un peu de tout',
                    netWeight: 10.0,
                    unitPrice: 17.0,
                    quantity: 8,
                    quantitySold: 1
                },
                {
                    id: 3,
                    label: 'Le coli cuisson rapide',
                    description: 'Un coli avec majoritairement des pièces à griller',
                    netWeight: 7.0,
                    unitPrice: 17.0,
                    quantity: 8,
                    quantitySold: 8,
                    photo: 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII='
                }
            ]
        }
    }

    getProductionPercentageSold(): number {
        return Math.floor(Math.random() * 100)
    }

    getPackageTemplates(): Array<PackageTemplate> {
        const template1 = {
            id: 1,
            label: 'Le coli tradition',
            description: 'Un coli avec un peu de tout',
            unitPrice: 16.5,
            netWeight: 10,
            photo: undefined
        }
        const template2 = {
            id: 2,
            label: 'Le coli cuisson rapide',
            description: 'Un coli avec surtout des steaks ',
            unitPrice: 16.5,
            netWeight: 10,
            photo: undefined
        }
        const template3 = {
            id: 3,
            label: 'Le petit coli steaks',
            description: 'Un coli avec que des steaks ',
            unitPrice: 16.5,
            netWeight: 5,
            photo: undefined
        }
        return [template1, template2, template3]
    }


    getProductionProducerPublicData() {
        return {
            id: 1,
            slideShowUrl: 'https://docs.google.com/presentation/d/1sqDR1v9AZLQYVJo2iT1gAVZ4UX25RamEBXeTmiUWRa4/embed?start=true&loop=true&delayms=3000',
            websiteUrl: 'https://docs.google.com/presentation/d/1sqDR1v9AZLQYVJo2iT1gAVZ4UX25RamEBXeTmiUWRa4/embed?start=true&loop=true&delayms=3000',
            farmName: 'La ferme des Marronniers'
        }
    }
}