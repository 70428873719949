"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeAccountToJSON = exports.StripeAccountFromJSONTyped = exports.StripeAccountFromJSON = exports.instanceOfStripeAccount = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the StripeAccount interface.
 */
function instanceOfStripeAccount(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfStripeAccount = instanceOfStripeAccount;
function StripeAccountFromJSON(json) {
    return StripeAccountFromJSONTyped(json, false);
}
exports.StripeAccountFromJSON = StripeAccountFromJSON;
function StripeAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'stripeId': !(0, runtime_1.exists)(json, 'stripeId') ? undefined : json['stripeId'],
        'detailsSubmitted': !(0, runtime_1.exists)(json, 'detailsSubmitted') ? undefined : json['detailsSubmitted'],
        'accountLink': !(0, runtime_1.exists)(json, 'accountLink') ? undefined : json['accountLink'],
    };
}
exports.StripeAccountFromJSONTyped = StripeAccountFromJSONTyped;
function StripeAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'stripeId': value.stripeId,
        'detailsSubmitted': value.detailsSubmitted,
        'accountLink': value.accountLink,
    };
}
exports.StripeAccountToJSON = StripeAccountToJSON;
