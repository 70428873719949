"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HonneyProductionToJSON = exports.HonneyProductionFromJSONTyped = exports.HonneyProductionFromJSON = exports.instanceOfHonneyProduction = void 0;
var runtime_1 = require("../runtime");
var Production_1 = require("./Production");
/**
 * Check if a given object implements the HonneyProduction interface.
 */
function instanceOfHonneyProduction(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfHonneyProduction = instanceOfHonneyProduction;
function HonneyProductionFromJSON(json) {
    return HonneyProductionFromJSONTyped(json, false);
}
exports.HonneyProductionFromJSON = HonneyProductionFromJSON;
function HonneyProductionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, Production_1.ProductionFromJSONTyped)(json, ignoreDiscriminator)), { 'totalWeight': !(0, runtime_1.exists)(json, 'totalWeight') ? undefined : json['totalWeight'], 'honeyDescription': !(0, runtime_1.exists)(json, 'honeyDescription') ? undefined : json['honeyDescription'] });
}
exports.HonneyProductionFromJSONTyped = HonneyProductionFromJSONTyped;
function HonneyProductionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, Production_1.ProductionToJSON)(value)), { 'totalWeight': value.totalWeight, 'honeyDescription': value.honeyDescription });
}
exports.HonneyProductionToJSON = HonneyProductionToJSON;
