"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatusToJSON = exports.OrderStatusFromJSONTyped = exports.OrderStatusFromJSON = exports.OrderStatus = void 0;
/**
 *
 * @export
 */
exports.OrderStatus = {
    BookedWithoutPayment: 'BOOKED_WITHOUT_PAYMENT',
    PaymentPending: 'PAYMENT_PENDING',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    PaymentAborted: 'PAYMENT_ABORTED',
    Delivered: 'DELIVERED'
};
function OrderStatusFromJSON(json) {
    return OrderStatusFromJSONTyped(json, false);
}
exports.OrderStatusFromJSON = OrderStatusFromJSON;
function OrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderStatusFromJSONTyped = OrderStatusFromJSONTyped;
function OrderStatusToJSON(value) {
    return value;
}
exports.OrderStatusToJSON = OrderStatusToJSON;
