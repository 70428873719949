"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressToJSON = exports.AddressFromJSONTyped = exports.AddressFromJSON = exports.instanceOfAddress = void 0;
var runtime_1 = require("../runtime");
var Producer_1 = require("./Producer");
/**
 * Check if a given object implements the Address interface.
 */
function instanceOfAddress(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAddress = instanceOfAddress;
function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
exports.AddressFromJSON = AddressFromJSON;
function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'addressLine1': !(0, runtime_1.exists)(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !(0, runtime_1.exists)(json, 'addressLine2') ? undefined : json['addressLine2'],
        'zipCode': !(0, runtime_1.exists)(json, 'zipCode') ? undefined : json['zipCode'],
        'owner': !(0, runtime_1.exists)(json, 'owner') ? undefined : (0, Producer_1.ProducerFromJSON)(json['owner']),
    };
}
exports.AddressFromJSONTyped = AddressFromJSONTyped;
function AddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'city': value.city,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'zipCode': value.zipCode,
        'owner': (0, Producer_1.ProducerToJSON)(value.owner),
    };
}
exports.AddressToJSON = AddressToJSON;
