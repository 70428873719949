"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeefProductionToJSON = exports.BeefProductionFromJSONTyped = exports.BeefProductionFromJSON = exports.instanceOfBeefProduction = exports.BeefProductionCattleBreedEnum = exports.BeefProductionAnimalTypeEnum = void 0;
var runtime_1 = require("../runtime");
var Production_1 = require("./Production");
/**
 * @export
 */
exports.BeefProductionAnimalTypeEnum = {
    Cow: 'BEEF_COW',
    Heifer: 'BEEF_HEIFER',
    Bull: 'BEEF_BULL',
    Veal: 'BEEF_VEAL'
};
/**
 * @export
 */
exports.BeefProductionCattleBreedEnum = {
    Limousine: 'LIMOUSINE',
    Charolaise: 'CHAROLAISE'
};
/**
 * Check if a given object implements the BeefProduction interface.
 */
function instanceOfBeefProduction(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfBeefProduction = instanceOfBeefProduction;
function BeefProductionFromJSON(json) {
    return BeefProductionFromJSONTyped(json, false);
}
exports.BeefProductionFromJSON = BeefProductionFromJSON;
function BeefProductionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, Production_1.ProductionFromJSONTyped)(json, ignoreDiscriminator)), { 'animalIdentifier': !(0, runtime_1.exists)(json, 'animalIdentifier') ? undefined : json['animalIdentifier'], 'animalType': !(0, runtime_1.exists)(json, 'animalType') ? undefined : json['animalType'], 'cattleBreed': !(0, runtime_1.exists)(json, 'cattleBreed') ? undefined : json['cattleBreed'], 'birthDate': !(0, runtime_1.exists)(json, 'birthDate') ? undefined : (new Date(json['birthDate'])), 'birthPlace': !(0, runtime_1.exists)(json, 'birthPlace') ? undefined : json['birthPlace'], 'birthFarm': !(0, runtime_1.exists)(json, 'birthFarm') ? undefined : json['birthFarm'], 'warmCarcassWeight': !(0, runtime_1.exists)(json, 'warmCarcassWeight') ? undefined : json['warmCarcassWeight'], 'meatWeight': !(0, runtime_1.exists)(json, 'meatWeight') ? undefined : json['meatWeight'], 'slaughterDate': !(0, runtime_1.exists)(json, 'slaughterDate') ? undefined : (new Date(json['slaughterDate'])), 'slaughterPlace': !(0, runtime_1.exists)(json, 'slaughterPlace') ? undefined : json['slaughterPlace'], 'slaughterHouse': !(0, runtime_1.exists)(json, 'slaughterHouse') ? undefined : json['slaughterHouse'], 'cuttingDate': !(0, runtime_1.exists)(json, 'cuttingDate') ? undefined : (new Date(json['cuttingDate'])), 'cuttingPlace': !(0, runtime_1.exists)(json, 'cuttingPlace') ? undefined : json['cuttingPlace'], 'cuttingButcher': !(0, runtime_1.exists)(json, 'cuttingButcher') ? undefined : json['cuttingButcher'], 'labelRougeCertified': !(0, runtime_1.exists)(json, 'labelRougeCertified') ? undefined : json['labelRougeCertified'] });
}
exports.BeefProductionFromJSONTyped = BeefProductionFromJSONTyped;
function BeefProductionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, Production_1.ProductionToJSON)(value)), { 'animalIdentifier': value.animalIdentifier, 'animalType': value.animalType, 'cattleBreed': value.cattleBreed, 'birthDate': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0, 10)), 'birthPlace': value.birthPlace, 'birthFarm': value.birthFarm, 'warmCarcassWeight': value.warmCarcassWeight, 'meatWeight': value.meatWeight, 'slaughterDate': value.slaughterDate === undefined ? undefined : (value.slaughterDate.toISOString().substr(0, 10)), 'slaughterPlace': value.slaughterPlace, 'slaughterHouse': value.slaughterHouse, 'cuttingDate': value.cuttingDate === undefined ? undefined : (value.cuttingDate.toISOString().substr(0, 10)), 'cuttingPlace': value.cuttingPlace, 'cuttingButcher': value.cuttingButcher, 'labelRougeCertified': value.labelRougeCertified });
}
exports.BeefProductionToJSON = BeefProductionToJSON;
