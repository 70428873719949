"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = void 0;
var runtime = require("../runtime");
var models_1 = require("../models");
/**
 *
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a new beef production
     */
    DefaultApi.prototype.createBeefProductionRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.beefProduction === null || requestParameters.beefProduction === undefined) {
                            throw new runtime.RequiredError('beefProduction', 'Required parameter requestParameters.beefProduction was null or undefined when calling createBeefProduction.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/beefProductions",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.BeefProductionToJSON)(requestParameters.beefProduction),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.BeefProductionFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Create a new beef production
     */
    DefaultApi.prototype.createBeefProduction = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createBeefProductionRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create a new customer
     */
    DefaultApi.prototype.createCustomerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.customer === null || requestParameters.customer === undefined) {
                            throw new runtime.RequiredError('customer', 'Required parameter requestParameters.customer was null or undefined when calling createCustomer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/customers",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.CustomerToJSON)(requestParameters.customer),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.CustomerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Create a new customer
     */
    DefaultApi.prototype.createCustomer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createCustomerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Creates a new instance of a `Order`.
     * Create a Order
     */
    DefaultApi.prototype.createOrderRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.order === null || requestParameters.order === undefined) {
                            throw new runtime.RequiredError('order', 'Required parameter requestParameters.order was null or undefined when calling createOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/orders",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.OrderToJSON)(requestParameters.order),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.OrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Creates a new instance of a `Order`.
     * Create a Order
     */
    DefaultApi.prototype.createOrder = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createOrderRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Creates an order and its payment that should be processes afterward on payment platform.
     * Creates a payment for the order given as a payload
     */
    DefaultApi.prototype.createOrderPaymentRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.order === null || requestParameters.order === undefined) {
                            throw new runtime.RequiredError('order', 'Required parameter requestParameters.order was null or undefined when calling createOrderPayment.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/orders/payment",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.OrderToJSON)(requestParameters.order),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.OrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Creates an order and its payment that should be processes afterward on payment platform.
     * Creates a payment for the order given as a payload
     */
    DefaultApi.prototype.createOrderPayment = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createOrderPaymentRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Creates a new instance of a `sale`.
     * Create a sale owned by a specific producer.
     */
    DefaultApi.prototype.createProducerSaleRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling createProducerSale.');
                        }
                        if (requestParameters.sale === null || requestParameters.sale === undefined) {
                            throw new runtime.RequiredError('sale', 'Required parameter requestParameters.sale was null or undefined when calling createProducerSale.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/sales".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.SaleToJSON)(requestParameters.sale),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.SaleFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Creates a new instance of a `sale`.
     * Create a sale owned by a specific producer.
     */
    DefaultApi.prototype.createProducerSale = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createProducerSaleRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.createStripeAccountRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling createStripeAccount.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["write"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/stripeAccount/".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.StripeAccountFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.createStripeAccount = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createStripeAccountRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get all addresses
     * Get all addresses
     */
    DefaultApi.prototype.getAddressesRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/addresses",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.AddressFromJSON); })];
                }
            });
        });
    };
    /**
     * Get all addresses
     * Get all addresses
     */
    DefaultApi.prototype.getAddresses = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAddressesRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `beefProduction`.
     * Get a beef production
     */
    DefaultApi.prototype.getBeefProductionRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.beefProductionId === null || requestParameters.beefProductionId === undefined) {
                            throw new runtime.RequiredError('beefProductionId', 'Required parameter requestParameters.beefProductionId was null or undefined when calling getBeefProduction.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/beefProductions/{beefProductionId}".replace("{".concat("beefProductionId", "}"), encodeURIComponent(String(requestParameters.beefProductionId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.BeefProductionFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `beefProduction`.
     * Get a beef production
     */
    DefaultApi.prototype.getBeefProduction = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBeefProductionRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a `Customer` entities.
     * Get a customer identified by its email.
     */
    DefaultApi.prototype.getCustomerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.email !== undefined) {
                            queryParameters['email'] = requestParameters.email;
                        }
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/customers",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.CustomerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a `Customer` entities.
     * Get a customer identified by its email.
     */
    DefaultApi.prototype.getCustomer = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCustomerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `honneyProduction`.
     * Get a honney production
     */
    DefaultApi.prototype.getHonneyProductionRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.honneyProductionId === null || requestParameters.honneyProductionId === undefined) {
                            throw new runtime.RequiredError('honneyProductionId', 'Required parameter requestParameters.honneyProductionId was null or undefined when calling getHonneyProduction.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/honneyProductions/{honneyProductionId}".replace("{".concat("honneyProductionId", "}"), encodeURIComponent(String(requestParameters.honneyProductionId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.HonneyProductionFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `honneyProduction`.
     * Get a honney production
     */
    DefaultApi.prototype.getHonneyProduction = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHonneyProductionRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `Order`.
     * Get a Order
     */
    DefaultApi.prototype.getOrderRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
                            throw new runtime.RequiredError('orderId', 'Required parameter requestParameters.orderId was null or undefined when calling getOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/orders/{orderId}".replace("{".concat("orderId", "}"), encodeURIComponent(String(requestParameters.orderId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.OrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `Order`.
     * Get a Order
     */
    DefaultApi.prototype.getOrder = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOrderRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get all package templates
     * Get all package templates
     */
    DefaultApi.prototype.getPackageTemplatesRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/packageTemplates",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.PackageTemplateFromJSON); })];
                }
            });
        });
    };
    /**
     * Get all package templates
     * Get all package templates
     */
    DefaultApi.prototype.getPackageTemplates = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPackageTemplatesRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a `Producer` entities.
     * Get a producer identified by its email.
     */
    DefaultApi.prototype.getProducerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.email !== undefined) {
                            queryParameters['email'] = requestParameters.email;
                        }
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ProducerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a `Producer` entities.
     * Get a producer identified by its email.
     */
    DefaultApi.prototype.getProducer = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProducerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a list of all `customer` entities.
     * List all customers who have bought anything to a specific producer.
     */
    DefaultApi.prototype.getProducerCustomersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling getProducerCustomers.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/customers".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.CustomerFromJSON); })];
                }
            });
        });
    };
    /**
     * Gets a list of all `customer` entities.
     * List all customers who have bought anything to a specific producer.
     */
    DefaultApi.prototype.getProducerCustomers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProducerCustomersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the payments summary for a producer
     */
    DefaultApi.prototype.getProducerPaymentsSummaryRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling getProducerPaymentsSummary.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/payments/summary".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.PaymentsSummaryFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the payments summary for a producer
     */
    DefaultApi.prototype.getProducerPaymentsSummary = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProducerPaymentsSummaryRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a list of all `sale` entities.
     * List all sales owned by a spacific producer.
     */
    DefaultApi.prototype.getProducerSalesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling getProducerSales.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/sales".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.SaleFromJSON); })];
                }
            });
        });
    };
    /**
     * Gets a list of all `sale` entities.
     * List all sales owned by a spacific producer.
     */
    DefaultApi.prototype.getProducerSales = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProducerSalesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.getProductionPercentageSoldRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.productionId === null || requestParameters.productionId === undefined) {
                            throw new runtime.RequiredError('productionId', 'Required parameter requestParameters.productionId was null or undefined when calling getProductionPercentageSold.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/productions/{productionId}/percentageSold".replace("{".concat("productionId", "}"), encodeURIComponent(String(requestParameters.productionId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        if (this.isJsonMime(response.headers.get('content-type'))) {
                            return [2 /*return*/, new runtime.JSONApiResponse(response)];
                        }
                        else {
                            return [2 /*return*/, new runtime.TextApiResponse(response)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.getProductionPercentageSold = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProductionPercentageSoldRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a `Producer` entities, with only public data for a production identified by its id.
     * Get a producer\'s public data for a production identified by its id.
     */
    DefaultApi.prototype.getProductionProducerPublicDataRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.productionId === null || requestParameters.productionId === undefined) {
                            throw new runtime.RequiredError('productionId', 'Required parameter requestParameters.productionId was null or undefined when calling getProductionProducerPublicData.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/productions/{productionId}/producer/publicData".replace("{".concat("productionId", "}"), encodeURIComponent(String(requestParameters.productionId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ProducerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a `Producer` entities, with only public data for a production identified by its id.
     * Get a producer\'s public data for a production identified by its id.
     */
    DefaultApi.prototype.getProductionProducerPublicData = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProductionProducerPublicDataRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a list of all `production` entities.
     * List All productions
     */
    DefaultApi.prototype.getProductionsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.forSale !== undefined) {
                            queryParameters['forSale'] = requestParameters.forSale;
                        }
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/productions",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.ProductionFromJSON); })];
                }
            });
        });
    };
    /**
     * Gets a list of all `production` entities.
     * List All productions
     */
    DefaultApi.prototype.getProductions = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProductionsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the public data for a random producer
     */
    DefaultApi.prototype.getRandomProducerPublicDataRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/random/publicData",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ProducerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the public data for a random producer
     */
    DefaultApi.prototype.getRandomProducerPublicData = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRandomProducerPublicDataRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `sale`.
     * Get a sale
     */
    DefaultApi.prototype.getSaleRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
                            throw new runtime.RequiredError('saleId', 'Required parameter requestParameters.saleId was null or undefined when calling getSale.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/sales/{saleId}".replace("{".concat("saleId", "}"), encodeURIComponent(String(requestParameters.saleId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.SaleFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets the details of a single instance of a `sale`.
     * Get a sale
     */
    DefaultApi.prototype.getSale = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSaleRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get orders for a particular sale
     */
    DefaultApi.prototype.getSaleOrdersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
                            throw new runtime.RequiredError('saleId', 'Required parameter requestParameters.saleId was null or undefined when calling getSaleOrders.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/sales/{saleId}/orders".replace("{".concat("saleId", "}"), encodeURIComponent(String(requestParameters.saleId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.OrderFromJSON); })];
                }
            });
        });
    };
    /**
     * Get orders for a particular sale
     */
    DefaultApi.prototype.getSaleOrders = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSaleOrdersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get orders for a particular sale
     */
    DefaultApi.prototype.getSaleProductionsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
                            throw new runtime.RequiredError('saleId', 'Required parameter requestParameters.saleId was null or undefined when calling getSaleProductions.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/sales/{saleId}/productions".replace("{".concat("saleId", "}"), encodeURIComponent(String(requestParameters.saleId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.ProductionFromJSON); })];
                }
            });
        });
    };
    /**
     * Get orders for a particular sale
     */
    DefaultApi.prototype.getSaleProductions = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSaleProductionsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets the list of `sale` instances, that are published by producers.
     * Get all the public sales
     */
    DefaultApi.prototype.getSalesRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/sales",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.SaleFromJSON); })];
                }
            });
        });
    };
    /**
     * Gets the list of `sale` instances, that are published by producers.
     * Get all the public sales
     */
    DefaultApi.prototype.getSales = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSalesRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.getStripeAccountRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling getStripeAccount.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}/stripeAccount/".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.StripeAccountFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    DefaultApi.prototype.getStripeAccount = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStripeAccountRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update a `Producer` entities.
     * Update a producer identified by its id.
     */
    DefaultApi.prototype.updateProducerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
                            throw new runtime.RequiredError('producerId', 'Required parameter requestParameters.producerId was null or undefined when calling updateProducer.');
                        }
                        if (requestParameters.producer === null || requestParameters.producer === undefined) {
                            throw new runtime.RequiredError('producer', 'Required parameter requestParameters.producer was null or undefined when calling updateProducer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("oAuth2ForViandeEnDirect", ["read"])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/producers/{producerId}".replace("{".concat("producerId", "}"), encodeURIComponent(String(requestParameters.producerId))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: (0, models_1.ProducerToJSON)(requestParameters.producer),
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ProducerFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Update a `Producer` entities.
     * Update a producer identified by its id.
     */
    DefaultApi.prototype.updateProducer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateProducerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return DefaultApi;
}(runtime.BaseAPI));
exports.DefaultApi = DefaultApi;
